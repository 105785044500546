
.react-datepicker-wrapper{
  flex: 1;
}

.react-datetimepicker-custom {
  font-family: "Roboto","Helvetica","Arial",sans-serif;
  border: 1px solid #ccc;
  padding: 15px 13px;
  border-radius: 5px;
  outline: none;
  font-size: 0.96em;
  color: #222;
  width: 100%;
}

.react-datetimepicker-custom:focus{
  border: 2px solid #1976d2;
}

.react-datepicker-popper {
  z-index: 100 !important;
}
